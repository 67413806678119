/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { RootState } from 'Util/Store/Store.type';

/** @namespace Poprawa/Util/Cart/getGrossOrderValue */
export const getGrossOrderValue = (state: RootState) => {
    const {
        CartReducer: {
            cartTotals: {
                prices: {
                    subtotal_including_tax: {
                        value: subtotal_incl_tax = 0,
                    } = {},
                } = {},
            } = {},
        } = {},
    } = state;

    return subtotal_incl_tax;
};

/** @namespace Poprawa/Util/Cart/getNetOrderValue */
export const getNetOrderValue = (state: RootState) => {
    const {
        CartReducer: {
            cartTotals: {
                prices: {
                    subtotal_excluding_tax: {
                        value: subtotal = 0,
                    } = {},
                } = {},
            } = {},
        } = {},
    } = state;

    return subtotal;
};
/** @namespace Poprawa/Util/Cart/getGrossOrderTotalValue */
export const getGrossOrderTotalValue = (state: RootState) => {
    const {
        CartReducer: {
            cartTotals: {
                prices: {
                    grand_total: {
                        value: subtotal_incl_tax = 0,
                    } = {},
                } = {},
            } = {},
        } = {},
    } = state;

    return subtotal_incl_tax;
};

/** @namespace Poprawa/Util/Cart/getNetOrderTotalValue */
export const getNetOrderTotalValue = (state: RootState) => {
    const {
        CartReducer: {
            cartTotals: {
                prices: {
                    subtotal_with_discount_excluding_tax: {
                        value: subtotal = 0,
                    } = {},
                } = {},
            } = {},
        } = {},
    } = state;

    return subtotal;
};

export * from 'SourceUtil/Cart/Cart';
