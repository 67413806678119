/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
import { lazy, Suspense } from 'react';

import RenderWhenVisible from 'Component/RenderWhenVisible';
import { WidgetFactoryComponent as SourceWidgetFactoryComponent } from 'SourceComponent/WidgetFactory/WidgetFactory.component';
import { lowPriorityLazy } from 'Util/Request/LowPriorityRender';

import { Widget } from './WidgetFactory.config';

export const Certificates = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "certificates" */
    'Component/Certificates'
));
export const PrivateSale = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "private-sale" */
    'Component/PrivateSale'
));

export const CMSLink = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "cms-link" */
    'Component/CMSLink'
));
export const ProductListWidget = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "widget-product-list" */
    'Component/ProductListWidget'
));
export const NewProducts = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "widget-new-product" */
    'Component/NewProducts'
));
export const HomeSlider = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "widget-slider" */
    'Component/SliderWidget'
));
export const RecentlyViewedWidget = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "widget-recently-viewed" */
    'Component/RecentlyViewedWidget'
));

/** @namespace Poprawa/Component/WidgetFactory/Component */
export class WidgetFactoryComponent extends SourceWidgetFactoryComponent {
    reactComponents = {
        CMSLink,
        Certificates,
        PrivateSale,
    };

    renderMap = {
        [Widget.SLIDER]: {
            component: HomeSlider,
            fallback: this.renderSliderFallback,
        },
        [Widget.NEW_PRODUCTS]: {
            component: NewProducts,
        },
        [Widget.CATALOG_PRODUCT_LIST]: {
            component: ProductListWidget,
        },
        [Widget.RECENTLY_VIEWED]: {
            component: RecentlyViewedWidget,
        },
        [Widget.REACT_COMPONENT]: {
            component: this.reactComponents[this.props.componentName],
        },
    };

    render() {
        return this.renderContent();
    }

    renderContent() {
        const {
            type,
            sliderId = 0,
            displayType,
            productsCount,
            showPager,
            storeId,
            title,
            conditionsEncoded,
            paramsJson,
            onLoad,
        } = this.props;
        const {
            component: Widget,
            fallback = () => <div />,
        } = this.renderMap[type] || {};

        if (Widget !== undefined) {
            return (
                <Suspense fallback={ fallback() }>
                    <RenderWhenVisible>
                        <Widget
                          sliderId={ sliderId }
                          displayType={ displayType }
                          productsCount={ productsCount }
                          showPager={ !!showPager }
                          storeId={ storeId }
                          title={ title }
                          conditionsEncoded={ conditionsEncoded }
                          onLoad={ onLoad }
                          paramsJson={ paramsJson }
                        />
                    </RenderWhenVisible>
                </Suspense>
            );
        }

        return null;
    }
}

export default WidgetFactoryComponent;
