/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { MyAccountQuery as SourceMyAccountQuery } from 'SourceQuery/MyAccount.query';

/**
 * MyAccount Mutations
 * @class MyAccount
 * @namespace Poprawa/Query/MyAccount/Query */
export class MyAccountQuery extends SourceMyAccountQuery {
    _getAddressFields() {
        return [
            'id',
            'customer_id',
            'country_id',
            'company',
            'street',
            'telephone',
            'postcode',
            'city',
            'firstname',
            'lastname',
            'middlename',
            'prefix',
            'suffix',
            'default_shipping',
            'default_billing',
            'vat_id',
            this._getRegionField(),
        ];
    }

    _getCustomerFields() {
        return [
            ...super._getCustomerFields(),
            'private_sale',
        ];
    }
}

export default new MyAccountQuery();
