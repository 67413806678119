/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import {
    Field,
} from '@tilework/opus';

import ProductListQuery from 'Query/ProductList.query';
import { CartQuery as SourceCartQuery } from 'SourceQuery/Cart.query';

/** @namespace Poprawa/Query/Cart/Query */
export class CartQuery extends SourceCartQuery {
    _getTierPricesField() {
        return new Field('price_tiers').addField('quantity');
    }

    _getCartItemProduct() {
        return new Field('product')
            .addFieldList([...ProductListQuery._getCartProductInterfaceFields(), 'sf_labels', this._getTierPricesField()]);
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new CartQuery();
