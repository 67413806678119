/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { connect } from 'react-redux';

import { CategoryPageContainer as SourceCategoryPageContainer, mapDispatchToProps, mapStateToProps } from 'SourceRoute/CategoryPage/CategoryPage.container';
import history from 'Util/History';
import {
    getQueryParam,
} from 'Util/Url';

export { mapDispatchToProps, mapStateToProps };

/** @namespace Poprawa/Route/CategoryPage/Container */
export class CategoryPageContainer extends SourceCategoryPageContainer {
    getIsMatchingListFilter() {
        const {
            currentArgs: {
                currentPage,
                sort,
                filter,
            } = {},
        } = this.props;
        const { location } = history;

        /**
     * ? implementation bellow blinks, implementation with categoryIds check only does not show loading when selecting filters.
     * TODO: resolve it to be a combination of these two behaviour
     */
        if (filter) {
            delete filter.ownerId;
        }

        // Data used to request category matches current data
        return JSON.stringify(filter) === JSON.stringify(this.getFilter())
        && JSON.stringify(sort) === JSON.stringify(this.getSelectedSortFromUrl())
        && currentPage === +(getQueryParam('page', location) || 1);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryPageContainer);
