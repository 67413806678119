/* eslint-disable no-magic-numbers */
/* eslint-disable no-const-assign */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { appendWithStoreCode } from 'Util/Url';

export const SHIPPING_STEP = 'SHIPPING_STEP';
export const BILLING_STEP = 'BILLING_STEP';
export const DETAILS_STEP = 'DETAILS_STEP';

export const BILLING_URL_STEP = 'billing';
export const SHIPPING_URL_STEP = 'shipping';
export const DETAILS_URL_STEP = 'success';

export const CHECKOUT_URL = '/checkout';
export const BILLING_URL = '/checkout/billing';
export const SHIPPING_URL = '/checkout/shipping';
export const REJECTED_URL = '/checkout/rejected';

export const CHECKOUT_URL_REGEX = new RegExp(`^(${appendWithStoreCode('')})?${CHECKOUT_URL}(/)?$`);

export const PAYMENT_TOTALS = 'PAYMENT_TOTALS';

export const UPDATE_EMAIL_CHECK_FREQUENCY = 1500; // ms
export const EXTERNAL_PAYMENT_ORDER_ID = 'EXTERNAL_PAYMENT_ORDER_ID';
export const STRIPE_PAYMENTS_CODE = 'stripe_payments';
export const TPAY_CODE = 'Tpay_Magento2';
export const TPAY_SECONDARY_CODE = 'generic-83';

export const BLIK = 'blik';
export const LAST_ORDER_EMAIL = 'lastOrderEmail';
export const LAST_ORDER_ID = 'lastOrderId';

export const INPOST_DATA = 'INPOST_DATA';
export const INPOST = 'inpostlocker';

export enum CheckoutSteps {
    SHIPPING_STEP = 'SHIPPING_STEP',
    BILLING_STEP = 'BILLING_STEP',
    DETAILS_STEP = 'DETAILS_STEP',
    REJECTED_STEP = 'REJECTED_STEP',
}

export enum CheckoutActivePage {
    BILLING_STEP = 3,
    SHIPPING_STEP = 2,
}

export enum CheckoutUrlSteps {
    BILLING_URL_STEP = 'billing',
    SHIPPING_URL_STEP = 'shipping',
    DETAILS_URL_STEP = 'success',
    REJECTED_URL_STEP = 'error',
}

export enum CheckoutStepUrl {
    CHECKOUT_URL = '/checkout',
    BILLING_URL = '/checkout/billing',
    SHIPPING_URL = '/checkout/shipping',
    REJECTED_URL = '/checkout/rejected',
}

export const UPDATE_SHIPPING_COST_ESTIMATES_FREQUENCY = 800; // ms
