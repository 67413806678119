/* eslint-disable import/no-anonymous-default-export */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
import {
    Field,
} from '@tilework/opus';

import { ProductCompareQuery as SourceProductCompareQuery } from 'SourceQuery/ProductCompare.query';

/** @namespace Poprawa/Query/ProductCompare/Query */
export class ProductCompareQuery extends SourceProductCompareQuery {
    _getCompareProductField() {
        return new Field('product')
            .addFieldList(this._getProductInterfaceFields(true, false))
            .addFieldList([new Field('url')])
            .addField(this._getReviewCountField())
            .addField(this._getRatingSummaryField())
            .addField(this._getDescriptionField())
            .addField(this._getGroupedProductItems())
            .addField(this._getDownloadableProductLinksRequired())
            .addField(this._getCustomizableProductFragment());
    }
}

export default new ProductCompareQuery();
